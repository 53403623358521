.slick-dots li.slick-active button:before {
  color: white !important;
}

.slick-dots li button:before {
  color: white !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  padding: unset !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
  z-index: 1000;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}

.crossword .clue {
  padding: 1px;
}

.crossword .header {
  font-weight: bold;
}

.tooltip::before {
  z-index: 50;
}

.tracker {
  height: 100%;
  width: 100%;
  position: relative;
}

.tracker .header {
  font-size: 1.875em;
  position: absolute;
  width: 100%;
}

.tracker-bar {
  display: flex;
  justify-content: center;
  /* position: absolute; */
  /* height: 5em; */
  top: 5.25em;
  width: 100%;
}

.tracker-base {
  width: 85%;
  max-width: 43.75em;
  /* background-color: #00587c; */
  height: 1em;
  border-radius: 2.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stages {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 1.5em;
  align-self: center;
  border-radius: 2.625em;
  overflow: hidden;
}

.stage {
  -ms-transform: skew(-45deg, 0deg);
  -webkit-transform: skew(-45deg, 0deg);
  transform: skew(-5deg, 0deg);
  /* background-color: #696969; */
  height: 100%;
  border-radius: 4px;
  flex-shrink: 0;
  margin-right: 0.75%;
}

.stage.stage-1 {
  width: 35%;
  margin-left: -6%;
}

.label.stage-1 {
  width: 19%;
}

.stage.stage-2,
.label.stage-2 {
  width: 30%;
}

.stage.stage-3,
.label.stage-3 {
  width: 30%;
  margin-right: 0%;
}

.stage.stage-4,
.label.stage-4 {
  width: 21%;
}

.stage.stage-5 {
  width: 28%;
}

.label.stage-5 {
  width: 22%;
}

.react-select__menu {
  z-index: 500;
  overflow: visible;
}

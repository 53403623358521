:root {
  --primary: tomato;
  --secondary: tomato;

  --cyan: #22b8cf;
  --green: #37b679;
  --red: #da3c3c;
  --pink: #e64980;
  --purple: #f81ce5;
  --blue: #0070f3;
  --violet-light: #7048e8;
  --violet: #5f3dc4;

  /* --accent: 'tomato';
  --neutral: '#2c3b50';
  --base-100: '#1f2937';
  --base-300: '#2c3b50';
  --base-content: 'white';

  --font-sans: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue',
    'Helvetica', sans-serif; */

  --header-height: 80px;
  --vh100-offset: calc(100vh - var(--header-height));
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  @apply outline-none ring-2 ring-white ring-opacity-50;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f2937;
  color: 'white';
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.height-screen-helper {
  height: var(--vh100-offset);
}
